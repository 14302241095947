<template>
	<div>
		<contentHeader title="Racikan Obat" />

		<div class="content-header">
			<div class="container-fluid">
				<div class="row justify-content-center">
					<div class="col">
						<div class="card">
							<div class="card-header row d-flex align-items-center">
		            			<h3 class="card-title col-6">Racikan Obat</h3>
		            			<div class="col-6 text-right">
		            				<router-link to="/tambah-racikan-obat"><button class="btn btn-sm btn-info">Tambah</button></router-link>	
		            			</div>
		            		</div>
		            		<div class="card-body">
		            			<table class="table table-bordered table-hover">
								  <thead>
								    <tr>
								      <th scope="col" class="text-sm">NO</th>	
								      <th scope="col" class="text-sm">KODE</th>
								      <th scope="col" class="text-sm">NAMA</th>
								      <th scope="col" class="text-sm">FREKUENSI</th>
								      <th scope="col" class="text-sm">WAKTU</th>
								      <th scope="col" class="text-sm">HARGA JUAL</th>
								      <th scope="col" class="text-sm">JUMLAH STOK</th>
								    </tr>
								  </thead>
								  <tbody>
								    <tr v-for="(row, index) in resep_obat" :key="row.id">
								      <td class="text-sm" scope="row">{{ index+1 }}</td>
								      <td class="text-sm" scope="row">{{ row.kode }}</td>
								      <td class="text-sm" scope="row">{{ row.nama }}</td>
								      <td class="text-sm" scope="row">{{ row.frekuensi_penggunaan }}</td>
								      <td class="text-sm" scope="row">{{ row.waktu_penggunaan }}</td>
								      <td class="text-sm" scope="row">{{ row.harga }}</td>
								      <td class="text-sm" scope="row">{{ row.stok }}</td>
								    </tr>
								  </tbody>
								</table>
								<p class="text-center" v-if="cek_resep">Data Masih Kosong</p>
								<p class="text-center" v-if="memuat_data">Memuat Data...</p>
		            		</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import ContentHeader from '@/components/ContentHeader'

	import { computed, onMounted, ref } from 'vue'
	import store from '@/store'
	import axios from 'axios'

	export default{
		components: {
			ContentHeader: ContentHeader
		},
		setup() {
			const user = computed(() => store.getters['auth/user'])
			const resep_obat = ref([])
			const memuat_data = ref(true)
			const cek_resep = ref(false)

			const get_resep_obat = async () => {
				let { data } = await axios.get(`api/resep_obat/get/${user.value.cabang_id}`)

				if (data == 'kosong') {
					memuat_data.value = false
					cek_resep.value = true
					resep_obat.value = []
				} else {
					memuat_data.value = false
					cek_resep.value = false
					resep_obat.value = data
				}
			}

			onMounted(() => {
				get_resep_obat()
			})

			return {
				resep_obat, memuat_data, cek_resep
			}

		}
	}
</script>